import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { User } from '../../models';
import { UtilsService } from '../../services';
import { NotificationService } from '../../services/notification.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { I } from '@angular/cdk/keycodes';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'a8-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  // animations: [inOutAnimation]
})
export class NavComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  url: string;
  previousUrl: string;
  profileid: string;
  flags = {
    sidenav: false,
    onProfile: false,
    animate_icon: false,
    showMenu: null,
    userImg: 'none',
  };
  version = environment.VERSION;
  user: User = null;
  notificationsLimit: number = 100;
  currentPortfolio: any;
  navComm: any;
  commUrl: any;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  // @ViewChild('messageDiv') messageDiv : ElementRef<HTMLInputElement>;
  @ViewChild('messageDiv', {static: false}) messageDiv: ElementRef;
  @ViewChild('messageText', {static: false}) messageText: ElementRef;
  @ViewChild('navtoolbar', {static: false}) navtoolbar: ElementRef;
  
  openMenu() {
    this.trigger.openMenu();
  }

  constructor(
    public utils: UtilsService,
    private notificationService: NotificationService,
    public router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    this.route.params.subscribe(
      (params) => (this.profileid = params['username'])
    );

    this.utils.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
      this.toggleAnimate();
    });

    // NOT updated before view loads?
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.url;
        this.url = event.url;

        this.flags.onProfile = false;
        let urlParts = this.url.split('/');
        if (urlParts[1] == 'profile' && urlParts.length == 3) {
          this.flags.onProfile = true;
        }
      }
    });

    this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      this.flags.userImg = this.getProfileBackground(this.user);
    });


    this.utils.appState.navComm.subscribe((resp) => {
      this.renderer.addClass(this.messageDiv.nativeElement, 'growNav');
      this.utils.delay(300).then(() => {
        this.renderer.addClass(this.messageText.nativeElement, 'textNotice');
      });

      this.navComm = resp.comm[0];
      this.commUrl = resp.url;
      let delay = 5000;
      let nextDelay = 0;
      // HIDE NAVCOMM AFTER
      this.utils.delay(delay * (resp.comm.length+4)).then(() => {
        this.utils.delay(400).then(() => {
          this.hideComm();
        });
      });

      resp.comm.forEach((comm, i) => {
        nextDelay = nextDelay + delay;
        console.log(nextDelay);
        if(resp['time'] != null){
          delay = resp['time'];
        }
        if(this.messageText != null){
        this.utils.delay(nextDelay).then(() => {
          this.renderer.removeClass(this.messageText.nativeElement, 'textNotice');
          this.utils.delay(100).then(() => {
            this.renderer.addClass(this.messageText.nativeElement, 'textNotice');
          });
          this.navComm = comm;
          this.messageText
        });
        }

      });






    });
  }

  hideComm(){
    this.renderer.removeClass(this.messageText.nativeElement, 'textNotice');
    this.renderer.removeClass(this.messageDiv.nativeElement, 'growNav');
    this.renderer.addClass(this.messageDiv.nativeElement, 'shrinkNav');
  }

  ngOnInit() { 

  }

  capacitor(){
    return Capacitor.isNativePlatform();
  }

  closeMenu(){
    if(this.flags.showMenu){
      this.flags.showMenu = this.flags.showMenu=false;
    }
  }

  delayAction(event, action, animation, delay?, activity?){
    if(delay == null){
      delay = 800;
    }
    // this.flags.clicked = true;
    this.renderer.addClass(event.currentTarget, animation);
    this.renderer.addClass(event.currentTarget, 'on');
    let item = event.currentTarget;

    this.utils.delay(delay).then(() => {
      this.renderer.removeClass(item, animation);
      this.renderer.removeClass(item, 'on');
      this.showPostBox('planning');
    });
  }

  toggleMenu(){
    if(this.flags.showMenu == null){
      this.flags.showMenu = true;
    }
    else{
      this.flags.showMenu = !this.flags.showMenu;
    }
  }

  showPostBox(type?) {
    this.utils.sound();
    this.user.app_state['createPostBox'] = type;
    this.utils.appState.user.next(this.user);
    this.router.navigateByUrl('/dashboard');
  }  

  toggleAnimate(){
    this.flags.animate_icon = !this.flags.animate_icon;
  }

  clickLogin() {
    this.router.navigateByUrl('/login?returnUrl=' + this.router.url);
  }

  showHome(){
    if(this.previousUrl != null && this.router.url != '/dashboard' && this.previousUrl.includes('returnUrl') 
    || this.previousUrl != null && this.flags?.onProfile && !this.previousUrl.includes('/dashboard/invites')){
      return true;
    }
    else{
      return false;
    }
  }

  logout() {
    if (confirm('Logout?')) {
      this.router.navigate(['/logout']);
    }
  }

  getProfileBackground(profile){
    if(profile != null && profile.profile_pics != null && profile.profile_pics.photos && profile.profile_pics.photos.length > 0){
      return 'url('+ profile.profile_pics.photos[profile.profile_pics.photos.length-1].image.sm +')';
    }
    else{
      return 'none';
    }  
  }

  back() {
    let urlParts = this.url.split('/');
    if (urlParts != null && urlParts[1] == 'profile' && urlParts[2] == null || urlParts.includes('settings')) {
      this.router.navigateByUrl('/dashboard');
    } else {
      this.location.back();
    }
    // this.location.replaceState(this.url);
    // this.location.replaceState(this.previousUrl);
  }

  toggleSidenav(): void {
    this.flags.sidenav = !this.flags.sidenav;
    this.utils.appState.sidenav.next(this.flags.sidenav);
  }

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }

}
