// import * as firebase from 'firebase/app';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component, AfterViewInit,
  ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, Renderer2
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import {
  ICalendarEvent, NgAddToCalendarService
} from '@trademe/ng-add-to-calendar';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';
import { BackgroundAnimations, inOutAnimation } from 'src/app/animations';
import { User } from 'src/app/models';
import { Activity } from 'src/app/models/activity';
import { ImageSnippet } from 'src/app/models/image';
import { Invite } from 'src/app/models/invite';
import { Like } from 'src/app/models/like';
import { GeoLocation } from 'src/app/models/location';
import { CurrentUserService, UserService } from 'src/app/services';
import { ActivityService } from 'src/app/services/activity.service';
import { BillingService } from 'src/app/services/billing.service';
import { FcmService } from 'src/app/services/fcm.service';
import { ImageService } from 'src/app/services/image.service';
import { InviteService } from 'src/app/services/invite.service';
import { LikeService } from 'src/app/services/like.service';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../services/utils.service';
import * as Flickity from 'flickity';
import { Post } from 'src/app/models/post';
import { Geolocation as CapLocate } from '@capacitor/geolocation';
import { element } from 'protractor';

@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  animations: [BackgroundAnimations, inOutAnimation],
})
export class StartComponent implements OnInit, OnDestroy {
  @Output() posting: EventEmitter<Boolean>;
  @Input() updatePostLocation: GeoLocation;
  @Input() updateColor: string;

  readonly VAPID_PRIVATE_KEY = 'BE5BoRyrh_Xcb80VK_qdQ69HgWqrGbpYpo4aes2xy8w';
  public googleCalendarEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;

  deviceInfo = null;
  user: User;
  subs: Subscription[] = [];
  bgImages = ['2', '3', '4', '5', '6', '7'];
  bgImg = this.bgImages[Math.floor(Math.random() * this.bgImages.length)];
  form: FormGroup;
  flags = {
    displayItem: 'colorpicker',
    loading: false,
    btnDoneParty: false,
    find: false,
    newUser: false,
    metricsLoaded: false,
    btnAddingActivities: true,
    noResults: false,
    gotOffestZero: false,
    noResults2: false,
    gotOffestZero2: false,
    posting: false,
    reviewing: false,
    activating: false,
    kudos: false,
    report: false,
    reviewAttend: null,
    reviewOverall: null,
    reviewCommunicate: null,
    randomActivity: null,
    showSetExperience: false,
    showSetDays: false,
    activatePostBtn: false,
    firstActivity: null,
    firstActivityPos: 0,
    hideActivityOptions: false,
    timeWidth: 100,
    day: null,
  };

  dashboardItem: string;
  storage: string =
    'https://firebasestorage.googleapis.com/v0/b/activity8-001.appspot.com/o/';

  showWelcome: boolean = true;
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  activityCtrl = new FormControl();
  filteredActivities: Observable<string[]>;
  activities: Activity[] = [];
  // allActivities: string[] = ['MTB', 'Yoga', 'Tennis', 'Chess', 'Noodling'];
  popularActivities: Activity[];
  setColor: boolean = false;
  likes: Like[];
  dayInvites: any = [];
  weekInviteStats: number;
  monthInviteStats: number;
  postCount: number;
  activityLog: any;
  selectedFile: ImageSnippet;

  loadLength: number = 20;
  resultOffset: number = 0;
  resultTotal: number = 0;
  loadLength2: number = 20;
  resultOffset2: number = 0;
  resultTotal2: number = 0;
  activity: Activity;
  userActivity: any;
  prices: any = ['Free', 1, 2, 3, 5, 'Verify to Unlock'];
  times: string[];
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  post: Post;
  locationDisplay: string;
  location: GeoLocation;


  @ViewChild('activityInput') activityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('imageInput') imageInput:ElementRef;
  @ViewChild('flickity') flickity:ElementRef;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public utils: UtilsService,
    private meService: CurrentUserService,
    private userService: UserService,
    private inviteService: InviteService,
    private likeService: LikeService,
    private swPush: SwPush,
    private notificationService: NotificationService,
    private _addToCalendarService: NgAddToCalendarService,
    private deviceService: DeviceDetectorService,
    private locationService: LocationService,
    private me: CurrentUserService,
    private imageService: ImageService,
    private billingService: BillingService,
    private fcm: FcmService,
    private activityService: ActivityService,
    private renderer: Renderer2,
  ) {

    const user = this.utils.appState.user.subscribe((resp) => {
      this.user = resp;
      if(this.user.color == null){
        this.flags.displayItem = 'colorpicker';
      }
      else if(this.user.is_single == null){
        this.flags.displayItem = 'single';
      }
      else if(this.user.gender == null) {
        this.flags.displayItem = 'gender';
      }
      else if(this.user.is_drinker == null || this.user.is_smoker == null) {
        this.flags.displayItem = 'party';
      }
      else if(this.user.activities.length == 0 && this.user.app_state.showSetActivities == null) {
        this.flags.displayItem = 'activities';
        this.user.app_state.showSetActivities = true;
      }
      else{
        this.router.navigateByUrl('/dashboard');
      }
      
    });
    this.subs.push(user);


  }

  ngOnInit() {


  }



  // TEMP NO NAME FIX / REQUEST & SAVE
  userForm: FormGroup;
  get uf() {
    return this.userForm.controls;
  } 
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'required': true };
  }

  // setupActivities(){
  //   if (this.user.activities != null && this.user.activities.length > 0) {

  //     this.user.activities.forEach(activity => {
  //       this.user.app_state.showSetExperience = null;
  //       if(!activity.is_interest){
  //         if(activity.experience == 0){
  //           this.flags.showSetExperience = true;
  //           this.activity = activity;
  //           this.user.app_state.showSetExperience = 'in';
  //           return;
  //         }

  //         if(!this.flags.showSetExperience){
  //           this.user.app_state.showSetDays = null;
  //           if(activity.days.length == 0 || activity.days == ''){
  //             this.flags.showSetDays = true;
  //             this.activity = activity;
  //             this.user.app_state.showSetDays = 'in';
  //             return;
  //           }
  //         }
  //       }
  //     });

  //   }    
  // }  

  nextActivity(userActivity){
    this.flags.showSetExperience = true;
    this.user.app_state.showSetExperience = null;
    this.activity = userActivity.activity;
    this.userActivity = userActivity;
    this.user.app_state.showSetExperience = 'in';
    this.flags.firstActivity = userActivity.activity;
    this.flags.firstActivityPos = 1;
  }

  experienceChanged(value){
    this.flags.newUser = false;
    this.flags.showSetExperience = false;
    this.flags.hideActivityOptions = true;
    this.flags.firstActivityPos = 2;

    this.user.app_state['createPostBox'] = 'firstPost';
    this.user.app_state['createPostBoxActivity'] = this.activity;

    // this.setupActivities();
  }

  daysChanged(value){
    this.flags.showSetDays = false;
    // this.setupActivities();
  }


  randomNumberBetween(length){
    if(length == 0){
      return 0;
    }
    return Math.floor(Math.random() * length) + 1;
  }
  randomTwo: Number = this.randomNumberBetween(2);
  randomThree: Number = this.randomNumberBetween(3);

  hasPhoto(){
    if (this.user.profile_pics == null || 
      (this.user.profile_pics != null && this.user.profile_pics.photos == null) || 
      (this.user.profile_pics != null && this.user.profile_pics.photos != null &&this.user.profile_pics.photos.length == 0)) {
        return false;
    }
    else{
      return true;
    }
  }


  clickColor(color) {
    this.user.color = color;
    this.flags.displayItem = 'single';
  }

  clickAddInterests() {
    this.utils.sound();
    this.utils.appState.user.next(this.user);
  }

  randomColor() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  }

  rndMatchCount() {
    return Math.floor(Math.random() * 5) + 1;
  }

  rndImg() {
    const imgs = ['sarah', 'rachelle', 'marissa', 'chris'];
    return imgs[Math.floor(Math.random() * imgs.length)];
  }

  delayAction(event, action, animation, delay?, activity?){
    this.utils.sound();
    if(delay == null){
      delay = 800;
    }
    
    
    let item = event.currentTarget;
    if (item.classList.contains('on')) {
      this.renderer.removeClass(item, 'on');
    }
    else{
      this.renderer.addClass(item, animation);
      this.renderer.addClass(item, 'on');
    }

    if(action == 'drink_false'){
      this.user.is_drinker = false;
      this.user.is_drinker_beer = false;
      this.user.is_drinker_wine = false;
      this.user.is_drinker_spirits = false;
    }
    else if (action.includes('drink_')){
      this.user.is_drinker = true;
    }
    else if(action == 'smoke_false'){
      this.user.is_smoker = false;
      this.user.is_smoker_cig = false;
      this.user.is_smoker_vape = false;
      this.user.is_smoker_weed = false;
    }
    else if (action.includes('smoke_')){
      this.user.is_smoker = true;
    }

    if(this.user.is_smoker != null && this.user.is_drinker != null){
      this.flags.btnDoneParty = true;
    }
    else{
      this.flags.btnDoneParty = false;
    }
    
    // AFTER DELAY
    this.utils.delay(delay).then(() => {
      this.renderer.removeClass(item, animation);
      if(this.flags.displayItem == 'colorpicker'){
        this.flags.displayItem = 'single';
      }      
      else if(this.flags.displayItem == 'single'){
        this.flags.displayItem = 'gender';
        if(action == 'single'){
          this.user.is_single = true;
        }
        else if(action == 'notsingle'){
          this.user.is_single = false;
        }
      }
      else if(this.flags.displayItem == 'gender'){
        if(action == 'M'){
          this.user.gender = 'M';
        }
        else if(action == 'F'){
          this.user.gender = 'F';
        }
        else if(action == 'T'){
          this.user.gender = 'T';
        }
        else if(action == 'S'){
          this.user.orientation = 'S';
        }
        else if(action == 'G'){
          this.user.orientation = 'G';
        }
        else if(action == 'B'){
          this.user.orientation = 'B';
        }
        if(!_.isEmpty(this.user.gender) && !_.isEmpty(this.user.orientation)){
          this.flags.displayItem = 'party';
        }
      }
      else if(this.flags.displayItem == 'party'){

        if(action == 'drink_false'){
          this.user.is_drinker = false;
          this.user.is_drinker_beer = false;
          this.user.is_drinker_wine = false;
          this.user.is_drinker_spirits = false;
        }
        else if(action == 'drink_beer'){
          if(this.user.is_drinker_beer){
            this.user.is_drinker_beer = false;
          }
          else{
            this.user.is_drinker = true;
            this.user.is_drinker_beer = true;
          }
        }
        else if(action == 'drink_wine'){
          if(this.user.is_drinker_wine){
            this.user.is_drinker_wine = false;
          }
          else{
            this.user.is_drinker = true;
            this.user.is_drinker_wine = true;
          }
        }
        else if(action == 'drink_spirits'){
          if(this.user.is_drinker_spirits){
            this.user.is_drinker_spirits = false;
          }
          else{
            this.user.is_drinker = true;
            this.user.is_drinker_spirits = true;
          }
        }

        if(action == 'smoke_false'){
          this.user.is_smoker = false;
          this.user.is_smoker_cig = false;
          this.user.is_smoker_vape = false;
          this.user.is_smoker_weed = false;
        }
        else if(action == 'smoke_true'){
          if(this.user.is_smoker_cig){
            this.user.is_smoker_cig = false;
          }
          else{
            this.user.is_smoker = true;
            this.user.is_smoker_cig = true;
          }
        }
        else if(action == 'smoke_vape'){
          if(this.user.is_smoker_vape){
            this.user.is_smoker_vape = false;
          }
          else{
            this.user.is_smoker = true;
            this.user.is_smoker_vape = true;
          }
        }
        else if(action == 'smoke_weed'){
          if(this.user.is_smoker_weed){
            this.user.is_smoker_weed = false;
          }
          else{
            this.user.is_smoker = true;
            this.user.is_smoker_weed = true;
          }
        }

      }
      else{
        // this.router.navigateByUrl(action);
      }
    });
  }  

  clickDoneParty(){
      this.utils.appState.user.next(this.user);
      this.meService.update({color:this.user.color, is_single:this.user.is_single, gender: this.user.gender, orientation: this.user.orientation, is_smoker: this.user.is_smoker, is_drinker: this.user.is_drinker}).subscribe();
  
      if(this.user.activities.length == 0) {
        this.flags.displayItem = 'activities';
        this.user.app_state.showSetActivities = true;
      }
      else{
        this.router.navigateByUrl('/dashboard');
      }
  }

  activitiesDone(){
    this.router.navigateByUrl('/dashboard');
  }

  back(){
    if(this.flags.displayItem == 'single'){
      // this.user.color = null;
      // this.flags.displayItem = 'colorpicker';
      this.activitiesDone();
    }      
    else if(this.flags.displayItem == 'gender'){
      this.flags.displayItem = 'single';
    }
    else if(this.flags.displayItem == 'gender'){
      this.flags.displayItem = 'welcome';
    }
    else if(this.flags.displayItem == 'party'){
      this.flags.displayItem = 'gender';
    }
    else if(this.flags.displayItem == 'activities'){
      this.flags.displayItem = 'party';
    }
    else{

    }
    this.displayItem();
  }

  displayItem(){
    return this.flags.displayItem;
  }


  // DATA
  // TEMP NO NAME FIX / REQUEST & SAVE
  saveUser(field) {
    if (this.uf[field].invalid) {
      return;
    }

    let data: any = {};
    if(field == 'first_name'){
      data.first_name = this.user.first_name;
    }
    else if(field == 'email'){
      data.email = this.user.email;
    }

    this.flags.loading = true;
    this.meService.update(data).subscribe(
      (data) => {
        this.utils.appState.user.next(this.user);
        // this.dashboardItem = this.displayDashboardItem();
        this.flags.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }

 

  ngOnDestroy() {
    _.each(this.subs, (sub) => {
      sub.unsubscribe();
    });
  }
}
