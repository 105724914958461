<div class="searchBase" [class.ios]="utils.ios()">

<app-search-settings *ngIf="flags.showSettings && user != null"></app-search-settings>
<!-- <app-search-settings *ngIf="user != null"></app-search-settings> -->

<div class="searchBar" [class.ios]="utils.ios()">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit();$event.target.blur()">
  <div fxlayout="row" class="searchCntent">
    <div  fxFlex="50px">
      <button class="dates" mat-button matTooltip="Events" (click)="display='posts';searchStr=null;utils.sound()" routerLink="/posts" onclick="window.scrollTo(0,0)" [disabled]="router.url == '/search' || router.url.includes('/posts')"><mat-icon>date_range</mat-icon></button>
    </div>  
    <div  fxFlex="50px">
      <button class="activities" mat-button matTooltip="Activities" (click)="display='activities';searchStr=null;utils.sound()" routerLink="/activities" onclick="window.scrollTo(0,0)" [disabled]="router.url.includes('/activities')"><mat-icon>accessibility_new</mat-icon></button>
    </div>  
    <div fxFlex="50px">
      <button class="members" mat-button matTooltip="Members" (click)="display='users';searchStr=null;utils.sound()" routerLink="/members" onclick="window.scrollTo(0,0)" [disabled]="router.url.includes('/members')"><mat-icon>face</mat-icon></button>
    </div>  
    <div fxFlex class="searchStr">
      <mat-form-field>
        <input matInput placeholder="Search" (click)="clearSearchInput()" (keydown)="onEnter($event)" (change)="activateBtn($event)" type="text" class="form-control" formControlName="searchStr" [ngClass]="{ 'is-invalid': flags.sfe && f.searchStr.invalid }" autocomplete="search" id="autofill-search">
        <mat-error *ngIf="f.searchStr.hasError('serverError')">
          <small>{{f.searchStr.errors.serverError}}</small>
        </mat-error>
        <mat-error *ngIf="f.searchStr.hasError('searchStr')">
          <small>Invalid Input</small>
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="30px" *ngIf="user != null">
      <button mat-button matTooltip="Settings" class="settingsBtn" (click)="flags.showSettings=!flags.showSettings" onclick="window.scrollTo(0,0)" *ngIf="!router.url.includes('/activities')"><mat-icon>settings</mat-icon></button>
    </div>  
    <div  fxFlex="16">
      <button mat-button type="submit" class="searchBtn" (click)="onSubmit()" [class.btnReady2]="f?.searchStr?.value?.length > 0" [disabled]="f?.searchStr?.value?.length == 0"><mat-icon>search</mat-icon></button>
    </div>  
  </div>
  </form>
</div>


<app-search-users (resultCount)="flags.userCount = $event" [searchStr]="searchStr" *ngIf="router.url.includes('/members') || (searchStr != null && searchStr != '')"></app-search-users>
<app-search-activities (resultCount)="flags.activityCount = $event" [searchStr]="searchStr" *ngIf="router.url.includes('/activities') || (searchStr != null && searchStr != '')"></app-search-activities>
<app-search-posts (resultCount)="flags.postCount = $event" [searchStr]="searchStr" *ngIf="router.url == '/search' || router.url.includes('/posts') || (searchStr != null && searchStr != '')"></app-search-posts>



  <div class="noResults" *ngIf="flags.userCount == 0 && flags.activityCount == 0 && flags.postCount == 0">
    <h2><mat-icon>sentiment_neutral</mat-icon></h2>
    <h2>No Results Found</h2>

    <br /><br />
    <button mat-button routerLink="/search" *ngIf="searchStr" (click)="searchStr=null;"><mat-icon>arrow_back</mat-icon> Back</button>
    <br /><br />
  </div>
  <br clear="all" />
</div>