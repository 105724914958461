import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { AlertService } from './alert.service';
import { AppStateService } from './app-state.service';
// import { EventBrokerService } from 'ng-event-broker';
import { Router } from '@angular/router';
import { User } from '../models';
import { Capacitor } from '@capacitor/core';
import * as moment from 'moment';
import { Zodiac } from '../models/zodiac';

declare var DjUrls: any;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  //ON AND AFTER
  premiumDate: string = '10/24/2024';
  user: User;
  flags = {
    sidenav: false,
  };

  private TOKEN_KEY = 'authToken';
  private _token: string;
  constructor(
    public alert: AlertService,
    public snackBar: MatSnackBar,
    public appState: AppStateService,
    public router: Router,
  ) {

    this.appState.user.subscribe((resp) => {
      this.user = resp;
    });

    this.appState.sidenav.subscribe((resp) => {
      this.flags.sidenav = resp;
    });

  }

  public getUrl(name: string, ...args: any[]) {
    // if (_.isEmpty(DjUrls[name])) {
      // CHECK IF ONLINE
      // https://www.npmjs.com/package/ng-connection-service
    // }
    try{
      const url = DjUrls[name].apply(this, args);
      return url;
    }
    catch{}

  }

  removeToken() {
    localStorage.removeItem(this.TOKEN_KEY);
    this._token = null;
  }

  set token(token: string) {
    this._token = token;
    // localStorage.setItem('isSameSite', 'Lax');
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  get token() {
    if (_.isEmpty(this._token)) {
      this._token = localStorage.getItem(this.TOKEN_KEY);
    }
    return this._token;
  }

  public showServerErrorsInForm(error: HttpErrorResponse, form: FormGroup) {
    if (_.has(error.error, 'non_field_errors')) {
      this.alert.error(error.error.non_field_errors);
    } else {
      this.alert.error('Please check form errors');
      _.each(_.keys(error.error), (key) => {
        if (form.contains(key)) {
          form.controls[key].setErrors({ serverError: error.error[key] });
        }
      });
    }
  }

  public requestOptions(qp?: any, responseType?) {
    const httpOptions = {};
    if (qp) {
      httpOptions['params'] = new HttpParams({ fromObject: qp });
    }
    return httpOptions;
  }

  public ios(){
    return Capacitor.isNativePlatform() && ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacPPC', 'Mac68K', 'Pike v7.6 release 92', 'Pike v7.8 release 517'].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  public android(){
    return Capacitor.isNativePlatform() && (navigator.platform.includes('Linux') || navigator.platform.includes('Android'));
  }

  public isNoon(date){
    if(date != null && date.includes('T20:00:00Z')){
      return true;
    }
  }

  public showPremium(user){
    const today = moment();
    //NO LIMIT UNTIL DATE
    if (today.isSameOrAfter(moment(this.premiumDate))) {
      return true;
    }
    return false;
  }

  public paywall(user, post?, profile?){
    //NO LIMIT SINGLE
    if(user != null && !user.is_single){
      return false;
    }
    
    //NO LIMIT UNTIL DATE
    const today = moment();
    if (today.isSameOrBefore(moment(this.premiumDate))) {
      return false;
    }
    //NO LIMIT IOS ONE DAY ADTER JOINED
    if (this.ios() && today.isBefore(moment(user.date_joined).add(1, 'days'))) {
      return false;
    }

    //PROFILE LIMIT
    if(profile != null && !user.is_premium && user.username != profile.username){
      this.user.app_state.paywallProfileCount++;
      if(this.user.app_state.paywallProfileCount != null && this.user.app_state.paywallProfileCount  > this.user.app_state.paywallLimit){
        this.user.app_state.paywallLimit = this.user.app_state.paywallLimit + 2;
        this.appState.user.next(this.user);
        return true;
      }
    }

    //POST LIMIT
    if(post != null && !user.is_premium && this.user.username != post.creator.username){
      this.user.app_state.paywallPostCount++;
      if(this.user.app_state.paywallPostCount != null && this.user.app_state.paywallPostCount  > this.user.app_state.paywallLimit){
        this.user.app_state.paywallLimit = this.user.app_state.paywallLimit + 2;
        return true;
      }
    }
    return false;
  }

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  humanDate(date){
    return moment(date).calendar(null,{
      lastDay : '[Yesterday]',
      sameDay : '[Today]',
      nextDay : '[Tomorrow]',
      lastWeek : '[last] dddd',
      nextWeek : 'ddd',
      sameElse : 'ddd'
    });
  }

  daysDiff(date){
    var today = moment(new Date);
    var postdate = moment(date);
    return postdate.diff(today, 'days');
    // today.startOf('day')
    // postdate.endOf('day')
  }

  displayPrice(price){
    if(!isNaN(price) && price != '0.00'){
      return '$' + Math.floor(Number(price));
    }
    else{
      return 'Free';
    }
  }

  copyText(text){
    this.sound('next');
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (text));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.alert.success('Copied to clipboard');
  }

  public sound(sound?) {
    if (this.user && this.user.settings && !this.user.settings.disable_sounds) {

      if (sound == null) {
        sound = "click";
      }

      let audio = new Audio();
      audio.src = "./assets/audio/" + sound + ".mp3";

      audio.load();
      audio.play();
    }
  }

  toggleSidenav(): void {
    this.sound();    
    this.flags.sidenav = !this.flags.sidenav;
    this.appState.sidenav.next(this.flags.sidenav);
  }



  zodiac(dateString: string){
    let dob = Date.parse(dateString);
    let year = new Date(dateString).getFullYear();

    let fromW = '03/22/' + year; let fromE = '04/14/' + year;
    let toW = '04/20/' + year; let toE = '05/14/' + year;
    let from = Date.parse(fromW);
    let to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Aries', from: from, to: to,
      colors: 'Red,White', stone: 'Garnet', days: 'Tuesday,Saturday,Friday', symbol: 'Ram', numbers: '1,9', planet: 'Mars', compatiable: 'Aries, Leo, Sagittarius'});
    }

    fromW = '04/21/' + year; fromE = '05/15/' + year;
    toW = '05/22/' + year; toE = '06/15/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Taurus', from: from, to: to,
      colors: 'Blue,Pink', stone: 'Emerald', days: 'Monday,Wednesday,Friday', symbol: 'Bull', numbers: '2,6', planet: 'Venus', compatiable: 'Virgo, Capricorn, Taurus'});
    }

    fromW = '05/21/' + year; fromE = '06/16/' + year;
    toW = '06/21/' + year; toE = '07/16/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Gemini', from: from, to: to,
      colors: 'Yellow,Blue', stone: 'Emerald', days: 'Wednesday,Thursday,Friday', symbol: 'Twins', numbers: '5', planet: 'Mercury', compatiable: 'Libra, Aquarius, Gemini'});
    }

    fromW = '06/22/' + year; fromE = '07/17/' + year;
    toW = '07/22/' + year; toE = '08/16/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Cancer', from: from, to: to,
      colors: 'White,Pale Yellow', stone: 'Moonstone', days: 'Tuesday,Thursday', symbol: 'Crab', numbers: '2,7', planet: 'Moon', compatiable: 'Scorpio, Pisces, Cancer'});
    }

    fromW = '07/23/' + year; fromE = '08/17/' + year;
    toW = '08/23/' + year; toE = '09/16/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Leo', from: from, to: to,
      colors: 'Yellow,Royal Blue', stone: 'Diamond', days: 'Sunday,Tuesday,Thursday', symbol: 'Lion', numbers: '1,4', planet: 'Sun', compatiable: 'Aries, Sagittarius, Leo'});
    }

    fromW = '08/24/' + year; fromE = '09/17/' + year;
    toW = '09/23/' + year; toE = '10/17/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Virgo', from: from, to: to,
      colors: 'Blue,Grey', stone: 'Jade', days: 'Monday,Wednesday,Friday', symbol: 'Virgin', numbers: '5', planet: 'Mercury', compatiable: 'Taurus, Virgo, Capricorn'});
    }

    fromW = '09/24/' + year; fromE = '10/18/' + year;
    toW = '10/23/' + year; toE = '11/16/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Libra', from: from, to: to,
      colors: 'Pink,Blue', stone: 'Emerald', days: 'Sunday,Monday,Saturday', symbol: 'Scales', numbers: '6', planet: 'Venus', compatiable: 'Aquarius, Gemini, Libra'});
    }

    fromW = '10/24/' + year; fromE = '11/17/' + year;
    toW = '11/22/' + year; toE = '12/15/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Scorpio', from: from, to: to,
      colors: 'Red,Brown', stone: 'Opal', days: 'Sunday,Monday,Thursday', symbol: 'Scorpion', numbers: '8', planet: 'Mars/Pluto', compatiable: 'Scorpio, Pisces, Cancer'});
    }

    fromW = '11/23/' + year; fromE = '12/17/' + year;
    toW = '12/22/' + year; toE = '1/20/' + (year+1);
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Sagittarius', from: from, to: to,
      colors: 'Golden,Yellow,Blue', stone: 'Black Pearl', days: 'Sunday,Wednesday,Thursday', symbol: 'Archer', numbers: '9', planet: 'Jupiter', compatiable: 'Aries, Leo, Sagittarius'});
    }

    fromW = '01/23/' + year; fromE = '02/13/' + year;
    toW = '02/20/' + year; toE = '03/14/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Aquarius', from: from, to: to,
      colors: 'Black,Purple', stone: 'Red Ruby', days: 'Saturday,Monday,Tuesday,Friday', symbol: 'Water Bearer', numbers: '1,2', planet: 'Saturn/Uranus', compatiable: 'Aquarius, Libra, Gemini'});
    }

    fromW = '02/21/' + year; fromE = '03/15/' + year;
    toW = '03/21/' + year; toE = '04/13/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    if (dob <= to && dob >= from) {
      return new Zodiac({name:'Pisces', from: from, to: to,
      colors: 'Green,Yellow', stone: 'Yellow Sapphire', days: 'Sunday,Tuesday,Thursday', symbol: 'Fish', numbers: '3', planet: 'Jupiter/Neptune', compatiable: 'Cancer, Scorpio, Pisces'});
    }

    fromW = '12/23/' + year; fromE = '01/15/' + year;
    toW = '01/22/' + (year+1); toE = '02/12/' + year;
    from = Date.parse(fromW);
    to = Date.parse(toW);
    // date spands over year change, maybe year+1 works for now being last return
    // if (dob <= to && dob >= from) {
      return new Zodiac({name:'Capricorn', from: from, to: to,
      colors: 'Black,Grey', stone: 'Sapphire,Garnet', days: 'Tuesday,Friday,Saturday', symbol: 'Goat', numbers: '1,4,8', planet: 'Saturn', compatiable: 'Taurus, Virgo, Capricorn'});
    // }
  }





}
