  <mat-progress-bar mode="indeterminate" class="loginProgress" *ngIf="flags.loading || (selectedFile != null && selectedFile.pending)"></mat-progress-bar>

<mat-card class="createPostComponent animate__animated" [class.animate__slideInDown]="user.app_state['accountBox'] == 'outRight'">
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <button mat-button type="button" class="closeBtn" (click)="togglePostBox(false, false)"><mat-icon>close</mat-icon></button>

    <mat-card-content class="loginCardContent">
      <h1>Edit play Date <mat-icon>date_range</mat-icon></h1>
        <!-- VIBE -->        
        <div fxlayout="row">
          <div fxFlex class="text-center when">
            <div class="item" [class.on]="post?.pets" class="first item option2 animate__animated animate__pulse" (click)="post.pets=!post.pets">
              <mat-icon class="largeIcon">pets</mat-icon>
              <p>Pets</p>
            </div>
            <div class="item" [class.on]="post?.family" class="item option2 animate__animated animate__pulse" (click)="post.family=!post.family">
              <mat-icon class="largeIcon">family_restroom</mat-icon>
              <p>Family</p>
            </div>
            <div class="item" [class.on]="post?.sober" class="item option2 animate__animated animate__pulse" (click)="post.sober=!post.sober">
              <mat-icon class="largeIcon">no_drinks</mat-icon>
              <p>Sober</p>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <mat-select placeholder="Activity" formControlName="activity" (selectionChange)="onSubmit(true)" [disabled]="post?.accepted_invite_count > 0" class="form-control" [ngClass]="{ 'is-invalid': flags.sfe && f.activityModel.invalid }">
                <mat-option [value]="post?.activity.id" *ngIf="post?.accepted_invite_count > 0">
                  {{post?.activity.name}}
                </mat-option>
                <ng-container *ngIf="post?.accepted_invite_count == 0">
                  <mat-option *ngFor="let activities of user?.activities" [value]="activities['activity'].id">
                    {{activities['activity'].name}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Duration" [(ngModel)]="post.duration" formControlName="duration">
                <mat-option *ngFor="let duration of durations" [value]="duration.value">
                  {{duration.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [disabled]="post?.accepted_invite_count > 0" (keyup)="activateBtn()" (dateChange)="activateBtn();dateChange()" type="text" formControlName="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.date.errors }">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
              <mat-error *ngIf="f.date.hasError('serverError')">
                <div>{{f.date.errors.serverError}}</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('birthday')">
                <div>Invalid entry.</div>
              </mat-error>
              <mat-error *ngIf="f.date.hasError('required')">
                <div>Required field.</div>
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Time" (selectionChange)="dateChange()" formControlName="time">
                <mat-option *ngFor="let time of times" [value]="time">
                  {{time}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field (click)="setLocation()">
              <input matInput placeholder="Location" type="text" autocomplete="off" (keyup)="activateBtn()" (change)="activateBtn();" class="form-control" formControlName="locationDisplay" [value]="locationDisplay" [ngClass]="{ 'is-invalid': flags.sfe && f.locationDisplay.invalid }">
              <mat-icon matSuffix>location_on</mat-icon>
              <mat-error *ngIf="f.locationDisplay.hasError('serverError')">
                <small>{{f.locationDisplay.errors.serverError}}</small>
              </mat-error>
              <mat-error *ngIf="f.locationDisplay.hasError('location')">
                <small>Invalid Location</small>
              </mat-error>
              <mat-error *ngIf="f.locationDisplay.hasError('required')">
                <small>Location is required</small>
              </mat-error>
            </mat-form-field>
            </div>
          <div fxFlex="35">
            <mat-form-field>
              <mat-select placeholder="Max People" formControlName="size">
                <mat-option *ngFor="let size of partysizes" [value]="size">
                  {{size}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="60">
            <mat-form-field>
                <mat-select placeholder="Public" [(ngModel)]="post.private" formControlName="private" [ngModel]="post.private">
                <mat-option [value]="false">Public</mat-option>
                <mat-option [value]="true">Private</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div fxFlex="35">
            <mat-form-field>
              <span matPrefix>$ &nbsp;</span>
                <mat-select placeholder="Price" formControlName="price" (selectionChange)="priceChange($event.value)" [value]="roundPrice(post.price)">
                <mat-option *ngFor="let price of prices" [value]="(price)">{{price}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex>
            <mat-form-field>
              <textarea matInput placeholder="Description" type="text" class="form-control" [(ngModel)]="post.description" formControlName="description" [ngClass]="{ 'is-invalid': flags.sfe && f.description.invalid }" autocomplete="post-description" id="autofill-edit-post-description"></textarea>
              <mat-error *ngIf="f.description.hasError('serverError')">
                <small>{{f.description.errors.serverError}}</small>
              </mat-error>
              <mat-error *ngIf="f.description.hasError('required')">
                <small>Invalid description</small>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- LINK -->
        <div class="item date" *ngIf="flags.link">
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex>
              <mat-form-field>
                <input matInput placeholder="eg. gregloveyoga.com" type="text" class="form-control" [(ngModel)]="post.url" formControlName="url" [ngClass]="{ 'is-invalid': flags.sfe && f.url.invalid }" autocomplete="post-url" id="autofill-post-url">
                <mat-error *ngIf="f.url.hasError('serverError')">
                  <small>{{f.url.errors.serverError}}</small>
                </mat-error>
                <mat-error *ngIf="f.url.hasError('url')">
                  <small>Invalid link</small>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- PHOTO & LINK -->
        <div class="item date buttons">
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="60" class="text-center">
              <button mat-button type="button" (click)="imageInput.click();" *ngIf="images?.length < 7">
                <mat-icon>photo_camera</mat-icon>
                <small> Add Photo</small>
              </button>
              <input hidden (change)="processFile(imageInput)" #imageInput type="file" accept="image/*">
            </div>
            <div fxFlex="60" class="text-center">
              <button mat-button type="button" *ngIf="!flags.link" (click)="flags.link=!flags.link">
                <mat-icon>link</mat-icon>
                <small> Add Link</small>
              </button>
              <button mat-button type="button" *ngIf="flags.link" (click)="flags.link=!flags.link;f.url.setValue('');post.url=''">
                <mat-icon>link_off</mat-icon>
                <small> Remove Link</small>
              </button>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex class="uploadedImages">
            <ng-container *ngFor="let image of images">
              <mat-icon (click)="deleteImage(image)" class="deleteImage">cancel</mat-icon>
              <img [src]="image.image" />
            </ng-container>

              <ng-container *ngFor="let image of post?.images?.photos">
                <mat-icon (click)="deleteImage(image)" class="deleteImage">cancel</mat-icon>
                <img [src]="image.image.sm" />
              </ng-container>
          </div>
        </div>

    </mat-card-content>
    <button mat-flat-button (click)="delete()" class="deleteBtn" *ngIf="post?.id && post?.accepted_invite_count == 0"><mat-icon>delete</mat-icon></button>
    <button [disabled]="flags.btnSubmit" mat-flat-button color="primary" class="item">Save &nbsp;  &nbsp;  &nbsp;  &nbsp; &nbsp; </button>
  </form>
</mat-card>

